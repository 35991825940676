import { Flex, HStack, Image, Text, VStack } from '@chakra-ui/react'
import CHECK_LARGE from '@page/logwork/assets/icons/check_large.svg'
import DISABLE_WARNING from '@page/logwork/assets/icons/disabled_warning.svg'
import {
    chakraComponents,
    DropdownIndicatorProps,
    GroupBase,
    OptionBase,
    OptionProps,
    Select,
} from 'chakra-react-select'
import { IconChevron } from '../../assets/icons/chevron'

interface Option extends OptionBase {
    icon?: string | null
    value: string
    label: string
    sublabel?: string
    disabled?: boolean | null
    disabledWarning?: string | null
}

type Props = {
    className?: string
    placeholder: string
    options: Option[]
    onChange: (args: { value: string; label: string } | unknown) => void
    values: string | undefined
    height?: string
    searchable?: boolean
    isDropdownOverflowed?: boolean
    isGroup?: boolean
    showArrow?: boolean
    menuListWidth?: string
    inputH?: string
    isBorder?: boolean
    isBorderOnHover?: boolean
    controlBorderColor?: string
    controlBorderColorOnHover?: string
    inputW?: string
    isControlBgTransparent?: boolean
    isError?: boolean
    isClearable?: boolean
    placeholderHoverBgColor?: string
    borderRadius?: string
    dropdownIndicatorBg?: string
    indicatorColor?: string
    isSelectedIcon?: boolean
    fontWeight?: string
    fontSize?: string
    placeholderColor?: string
    isPlaceholderLowercase?: boolean
    isPlaceholderUppercase?: boolean
    isDisabled?: boolean
    paddingX?: string
    removeRightPadding?: boolean
    isWhiteSpace?: boolean
    optionFontSize?: string
    showFocusedBorder?: boolean
    controlFontSize?: string
    optionPaddingX?: string
}

export const DefaultSelect = ({
    options,
    className,
    placeholder,
    onChange,
    values,
    height = '180px',
    searchable = true,
    isDropdownOverflowed = false,
    isGroup = false,
    showArrow = true,
    menuListWidth,
    inputH,
    isBorder = true,
    isBorderOnHover = true,
    inputW = '100%',
    isControlBgTransparent = false,
    isError = false,
    isClearable = false,
    placeholderHoverBgColor,
    borderRadius,
    dropdownIndicatorBg,
    indicatorColor = '#2F80ED',
    isSelectedIcon = false,
    fontWeight = 'normal',
    placeholderColor,
    isPlaceholderLowercase = false,
    isPlaceholderUppercase = false,
    isDisabled = false,
    paddingX = '16px',
    removeRightPadding = false,
    isWhiteSpace = false,
    optionFontSize = 'sm',
    controlFontSize = 'sm',
    showFocusedBorder = true,
    optionPaddingX = '12px',
    controlBorderColorOnHover = '#E0E0E0',
    controlBorderColor = '#E0E0E0',
}: Props) => {
    const components = {
        DropdownIndicator: (
            props: JSX.IntrinsicAttributes & DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
        ) =>
            showArrow ? (
                <chakraComponents.DropdownIndicator {...props}>
                    <IconChevron width={2} height={1} strokeColor={'#2F80ED'} />
                </chakraComponents.DropdownIndicator>
            ) : null,
        Option: (props: OptionProps<Option, boolean, GroupBase<Option>>) => (
            <chakraComponents.Option {...props}>
                <Flex w={'full'} justifyContent={'space-between'}>
                    <VStack alignItems={'flex-start'} w={'full'}>
                        <HStack>
                            {props.data.icon && (
                                <Image
                                    maxW={5}
                                    src={`${process.env.ENV === 'local' ? process.env.API_URL : window.location.origin}/${
                                        props.data.icon
                                    }`}
                                />
                            )}
                            <Text
                                overflow={'hidden'}
                                textOverflow={'ellipsis'}
                                whiteSpace={isWhiteSpace ? 'nowrap' : 'normal'}
                                maxW={isWhiteSpace ? `calc(${menuListWidth} - 50px)` : '100%'}
                                fontWeight={fontWeight}
                            >
                                {props.data.label}
                            </Text>
                        </HStack>
                        {props.data.sublabel && (
                            <HStack w={'full'} justifyContent={'space-between'} pr={'12px'}>
                                {props.data.sublabel && (
                                    <Text fontSize={'xs'} color={'text.thirdly'}>
                                        {props.data.sublabel}
                                    </Text>
                                )}
                                {props.data.disabledWarning && (
                                    <Text fontSize={'xs'} color={'#717175'}>
                                        {props.data.disabledWarning}
                                    </Text>
                                )}
                            </HStack>
                        )}
                    </VStack>
                    {props.isSelected && isSelectedIcon && <Image ml={'auto'} src={CHECK_LARGE} maxW={'16px'} />}
                    {props.data.disabledWarning && <Image ml={'auto'} src={DISABLE_WARNING} maxW={'16px'} />}
                </Flex>
            </chakraComponents.Option>
        ),
    }

    const defaultValue = (options: Option[], value: string) => {
        if (value === '') return null
        else return options ? options.find((option) => option.value === value) : ''
    }

    const controlBorder = isError ? '#FF897A' : controlBorderColor

    return (
        <Select
            className={`react-select-continer ${className}`}
            classNamePrefix={'react-select'}
            placeholder={placeholder}
            options={options}
            value={defaultValue(options, values ? values : '')}
            defaultValue={defaultValue(options, values ? values : '')}
            onChange={(value) => {
                onChange(value)
            }}
            // @ts-ignore
            components={components}
            isSearchable={searchable}
            isClearable={isClearable}
            isDisabled={isDisabled}
            noOptionsMessage={() => null}
            // @ts-ignore
            isOptionDisabled={(option) => {
                const value = option as Option
                const isDisabled = value.disabled || false
                return isDisabled
            }}
            menuPortalTarget={isDropdownOverflowed ? document.querySelector('body') : null}
            chakraStyles={{
                dropdownIndicator: (prev, { selectProps: { menuIsOpen }, isFocused }) => ({
                    ...prev,
                    '> svg': {
                        transitionDuration: 'normal',
                        transform: `rotate(${menuIsOpen ? -180 : 0}deg)`,
                        '> path': {
                            stroke: isDisabled ? '#CBCBCD' : indicatorColor,
                        },
                    },
                    width: '20px',
                    height: '20px',
                    marginRight: '10px',
                    marginLeft: '10px',
                    padding: '0',
                    bg: isFocused ? dropdownIndicatorBg : 'transparent',
                    borderRadius: '4px',
                    _hover: { bg: dropdownIndicatorBg ? dropdownIndicatorBg : 'transparent' },
                }),
                option: (provided, state) => ({
                    ...provided,
                    fontSize: optionFontSize,
                    color: 'text.main !important',
                    bg: state.isFocused ? 'bg.3 !important' : 'bg.1 !important',
                    fontWeight: 'normal',
                    textTransform: 'none',
                    paddingLeft: optionPaddingX,
                    paddingRight: optionPaddingX,
                }),
                placeholder: (provided) => ({
                    ...provided,
                    color: isDisabled ? '#B6B6B9' : placeholderColor ? placeholderColor : 'text.thirdly',
                    ...(isPlaceholderLowercase
                        ? { textTransform: 'lowercase' }
                        : isPlaceholderUppercase
                          ? { textTransform: 'uppercase' }
                          : {
                                '&': { textTransform: 'lowercase' },
                                '&:first-letter': { textTransform: 'uppercase' },
                            }),
                }),
                menuList: (base) => ({
                    ...base,
                    maxH: height,
                    marginTop: '-5px',
                    padding: 0,
                    zIndex: 5555,
                    width: menuListWidth ? menuListWidth : '100%',
                }),
                control: (base, state) => ({
                    ...base,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    borderRadius: borderRadius ? borderRadius : '8px',
                    borderTopRightRadius: isGroup ? 'none !important' : borderRadius ? borderRadius : '8px',
                    borderBottomRightRadius: isGroup ? 'none !important' : borderRadius ? borderRadius : '8px',
                    minHeight: inputH ? inputH : '40px',
                    border: isBorder ? '1px solid' : 'none',
                    borderColor: isBorder ? controlBorder : 'none',
                    _hover: {
                        bg: placeholderHoverBgColor || base.bg,
                        '.react-select__clear-indicator': {
                            display: 'flex',
                        },
                        border: isBorderOnHover ? '1px solid' : 'none',
                        borderColor: isBorderOnHover ? controlBorderColorOnHover : controlBorder,
                    },
                    _focus: { border: !isBorder && 'none', bg: '#FFF' },
                    maxW: inputW,
                    bg: isDisabled ? '#F5F5F5' : isControlBgTransparent ? 'transparent' : '#FFF',
                    outline: showFocusedBorder ? (state.isFocused ? '1px solid #2F80ED' : 'none') : 'none',
                }),
                valueContainer: (base) => ({
                    ...base,
                    fontWeight: 'normal',
                    textTransform: 'none',
                    fontSize: controlFontSize,
                    paddingLeft: paddingX,
                    paddingRight: removeRightPadding ? '0' : paddingX,
                }),
                clearIndicator: (base) => ({
                    ...base,
                    width: '24px',
                    height: '24px',
                    '> svg': {
                        width: '8px',
                        height: '8px',
                        color: '#333',
                    },
                    display: 'none',
                }),
            }}
        />
    )
}
